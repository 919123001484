import React, { createContext, useEffect } from 'react';
import {
  FormState,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { USState } from '@nirvana/api/claims';
import useAuth from 'src/hooks/useAuth';
import {
  PolicyCoverageEnums,
  useFleetDetailsQuery,
} from 'src/types/graphql-types';

export const DESCRIPTION_MAX_LENGTH = 5_000;

const ReporterInformation = z.object({
  firstName: z.string().nonempty().default(''),
  lastName: z.string().nonempty().default(''),
  phone: z.string().regex(/^\(?\d{3}\)? ?\d{3}-?\d{4}$/),
  email: z.string().email(),
});

const ClaimSchema = z
  .object({
    reporter: ReporterInformation,
    insuredName: z.string().nonempty().default(''),
    insuredDOT: z.string().default(''),
    noticeType: z
      .enum(['initiateClaim', 'onlyReporting'])
      .default('initiateClaim'),
    lossDate: z.date().default(new Date()),
    lossState: z.nativeEnum(USState),
    lossLocation: z.string().default(''),
    policyNumber: z.string().nonempty().default(''),
    lineOfBusiness: z.custom<PolicyCoverageEnums>(),
    ownVehiclesInvolved: z.string().default('yes'),
    insuredVehicleVins: z.string().array().default(['']),
    otherVehiclesInvolved: z.string().default('yes'),
    otherVehicleVins: z.string().array().default(['']),
    police: z.object({
      onTheScene: z.string().default('no'),
      agencyName: z.string().default(''),
      reportNumber: z.string().default(''),
    }),
    injureds: z.string().default('yes'),
    description: z.string().max(DESCRIPTION_MAX_LENGTH).default(''),
    attachments: z.array(z.custom<File>((v) => v instanceof File)).default([]),
  })
  .required({
    lossDate: true,
    lossState: true,
    policyNumber: true,
  });

type ClaimType = z.infer<typeof ClaimSchema>;

export const NewClaimContext = createContext<{
  formState: FormState<ClaimType>;
  getValues: UseFormGetValues<ClaimType>;
  register: UseFormRegister<ClaimType>;
  resetField: UseFormResetField<ClaimType>;
  setValue: UseFormSetValue<ClaimType>;
  watch: UseFormWatch<ClaimType>;
}>({
  formState: {} as FormState<ClaimType>,
  getValues: (() => {}) as UseFormGetValues<ClaimType>,
  register: {} as UseFormRegister<ClaimType>,
  resetField: (() => {}) as UseFormResetField<ClaimType>,
  setValue: (() => {}) as UseFormSetValue<ClaimType>,
  watch: (() => {}) as UseFormWatch<ClaimType>,
});

export const NewClaimProvider: React.FC = ({ children }) => {
  const { formState, getValues, register, resetField, setValue, watch } =
    useForm<ClaimType>({
      mode: 'onChange',
      resolver: zodResolver(ClaimSchema),
      defaultValues: {
        reporter: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
        },
        lossDate: new Date(),
        police: {
          onTheScene: 'yes',
        },
        otherVehiclesInvolved: 'yes',
        ownVehiclesInvolved: 'yes',
        noticeType: 'initiateClaim',
        insuredVehicleVins: [''],
        otherVehicleVins: [''],
        injureds: 'yes',
        attachments: [],
      },
    });

  const { user } = useAuth();
  const { reportId = '' } = useParams();
  const { data: fleetData } = useFleetDetailsQuery({ variables: { reportId } });

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.name) {
      const [firstName, lastName] = user.name.split(' ');
      setValue('reporter.firstName', firstName);
      setValue('reporter.lastName', lastName);
    }
    if (user.email) {
      setValue('reporter.email', user.email);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (fleetData) {
      setValue('insuredDOT', fleetData.fleetSafetyReport?.dotNumber || '');
      setValue('insuredName', fleetData.fleetSafetyReport?.name || '');
    }
  }, [fleetData, setValue]);

  return (
    <NewClaimContext.Provider
      value={{
        formState,
        getValues,
        register,
        resetField,
        setValue,
        watch,
      }}
    >
      {children}
    </NewClaimContext.Provider>
  );
};
