import { Chip, Tooltip, getFormattedDateInTimezone } from '@nirvana/ui-kit';
import {
  Column,
  FilterMeta,
  Row,
  createColumnHelper,
} from '@tanstack/react-table';

import { BASICS, ExtendedCategory } from 'src/constants';
import { Category, ViolationsQuery } from 'src/types/graphql-types';

export const filterFns = {
  violationGroup: (row: Row<any>, column: string, filterValue: any) => {
    const options = filterValue.split(',');
    return options.includes(row.original.group.name);
  },
  stringEqualsAny: (row: Row<any>, column: string, filterValue: any) => {
    const options = filterValue.split(',');
    return options.includes(row.getValue(column));
  },
  arrayIncludesAny: (row: Row<any>, column: string, filterValue: any) => {
    const options = filterValue.split(',');
    return row.original[column].some((value: string) =>
      options.includes(value),
    );
  },
  globalFilter: (
    row: Row<any>,
    column: string,
    filterValue: any,
    addMeta: (meta: FilterMeta) => void,
  ) => {
    const options = filterValue.split(',');

    const { globalFilterFn } =
      row.getAllCells().filter((cell) => cell.column.id === column)[0].column
        .columnDef.meta || {};

    if (globalFilterFn) {
      return globalFilterFn(row, column, filterValue, addMeta);
    }

    return options.includes(row.getValue(column));
  },
};

type Violation = NonNullable<
  ViolationsQuery['fleetSafetyReport']
>['inspections'][0]['violations'][0];

export type JointInspectionsData = Omit<
  Violation,
  'category' | 'severityWeight' | 'group'
> & {
  inspectionDate: string;
  vINs: string[];
  location: {
    countyCode: string;
    countyName: string;
  };
  driver: {
    name?: string;
    licenseNumber?: string;
  };
  group: Omit<Violation['group'], 'category'> & {
    category: ExtendedCategory | Category;
  };
  severityWeight: number | ExtendedCategory.Pending;
  ponderatedWeight: number;
  formattedInspectionDate: string;
  category: ExtendedCategory | Category;
  dateInSeconds: number;
};

const toggleFilter = (column: Column<JointInspectionsData>, value: any) => {
  if (column.getFilterValue() === value) {
    column.setFilterValue(undefined);
  } else {
    column.setFilterValue(value);
  }
};
export const getColumns = () => {
  const columnHelper = createColumnHelper<JointInspectionsData>();
  return [
    columnHelper.accessor('humanReadableCode', {
      id: 'violation',
      header: 'Violation',
      filterFn: 'includesString',
      cell: ({ row, column }) => (
        <div>
          <button
            className="font-semibold text-left text-secondary-main"
            onClick={() => toggleFilter(column, row.original.humanReadableCode)}
          >
            {row.original.humanReadableCode}
          </button>
          <p className="text-text-hint">{row.original.description}</p>
        </div>
      ),
    }),
    columnHelper.accessor('group.name', {
      header: 'Violation group',
      filterFn: 'violationGroup' as any,
      cell: ({ row, column }) => (
        <button
          className="text-left"
          onClick={() => toggleFilter(column, row.original.group.name)}
        >
          {row.original.group.humanReadable}
        </button>
      ),
      meta: {
        placeholder: 'Select Violation Group',
        filterVariant: 'select',
        globalFilterFn(row, column, filterValue) {
          const value = filterValue.toLowerCase();
          const option = row.original.group.humanReadable.toLowerCase();
          return option.includes(value);
        },
      },
    }),
    columnHelper.accessor('category', {
      header: 'BASIC Category',
      filterFn: 'stringEqualsAny' as any,
      cell: ({ getValue, column }) => (
        <button
          className="text-left"
          onClick={() => toggleFilter(column, getValue())}
        >
          {BASICS[getValue()]}
        </button>
      ),
      meta: {
        placeholder: 'Select BASIC Category',
        filterVariant: 'select',
        globalFilterFn(row, column, filterValue) {
          const value = filterValue.toLowerCase();
          const option =
            BASICS[row.getValue(column) as keyof typeof BASICS].toLowerCase();
          return option.includes(value);
        },
      },
    }),
    columnHelper.accessor('severityWeight', {
      header: 'Severity Weight',
      filterFn: 'equals',
      cell: ({ column, getValue }) => {
        return (
          <button
            onClick={() => toggleFilter(column, getValue())}
            className="px-4 py-1 rounded text-error-main bg-error-extraLight"
          >
            {getValue() === ExtendedCategory.Pending ? 'Pending' : getValue()}
          </button>
        );
      },
      meta: {
        globalFilterFn(row, column, filterValue) {
          const value = Number(filterValue);
          const option = row.getValue(column);
          return value === option;
        },
      },
    }),
    columnHelper.accessor('location.countyCode', {
      header: 'Location',
      filterFn: 'stringEqualsAny' as any,
      cell: ({ row, column }) => (
        <button
          className="text-left"
          onClick={() => toggleFilter(column, row.original.location.countyCode)}
        >
          {row.original.location.countyName}
        </button>
      ),
      meta: {
        placeholder: 'Select Location',
        filterVariant: 'select',
        globalFilterFn(row, column, filterValue) {
          const value = filterValue.toLowerCase();
          const option = row.original.location.countyName.toLowerCase();
          return option.includes(value);
        },
      },
    }),
    columnHelper.accessor((row) => row.vINs[0], {
      header: 'VIN',
      id: 'vINs',
      cell: ({ row, column }) => (
        <button
          onClick={() => toggleFilter(column, row.original.vINs[0])}
          className="flex items-center space-x-2"
        >
          <p className="truncate">{row.original.vINs[0]}</p>
          {row.original.vINs.length > 1 ? (
            <Tooltip
              arrow
              title={
                <div className="p-1">
                  <p className="font-semibold text-text-hint">Truck</p>
                  <p className="mb-2">{row.original.vINs[0]}</p>
                  <p className="font-semibold text-text-hint">Trailer(s)</p>
                  {row.original.vINs.slice(1).map((vin: string) => (
                    <li key={vin}>{vin}</li>
                  ))}
                </div>
              }
            >
              <span>
                <Chip color="tint" label={`+${row.original.vINs.length - 1}`} />
              </span>
            </Tooltip>
          ) : null}
        </button>
      ),
      filterFn: 'arrayIncludesAny' as any,
      meta: {
        placeholder: 'Select VIN',
        filterVariant: 'select',
        globalFilterFn(row, column, filterValue) {
          const value = filterValue.toLowerCase();
          const option = row.original.vINs.map((vin: string) =>
            vin.toLowerCase(),
          );
          return option.some((vin: string) => vin.includes(value));
        },
      },
    }),
    columnHelper.accessor('driver.name', {
      id: 'driver',
      header: 'Driver',
      cell: ({ row, column }) => (
        <button
          onClick={() => toggleFilter(column, row.original.driver.name)}
          className="text-left"
        >
          {row.original.driver.name}
        </button>
      ),
      filterFn: 'equals',
      meta: {
        placeholder: 'Select Driver',
        filterVariant: 'select',
        globalFilterFn(row, column, filterValue) {
          const value = filterValue;
          const option = row.original.driver.name;
          return option?.toLowerCase().includes(value.toLowerCase()) ?? false;
        },
      },
    }),
    columnHelper.accessor('inspectionDate', {
      header: 'Inspection Date',
      cell: ({ getValue, column }) => (
        <button onClick={() => toggleFilter(column, getValue())}>
          {getFormattedDateInTimezone(getValue())}
        </button>
      ),
      meta: {
        globalFilterFn(row, column, filterValue) {
          const value = filterValue;
          const option = getFormattedDateInTimezone(
            row.original.inspectionDate,
          );
          return option.includes(value);
        },
      },
    }),
  ];
};
