import React from 'react';
import { IconButton, ListItemText } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { ReactComponent as DocumentIcon } from 'src/assets/icons/document.svg';
import { ReactComponent as PdfIcon } from 'src/assets/icons/pdf.svg';

interface FileListItemProps {
  file: File;
  onRemove?: () => void;
}

export const FileListItem: React.FC<FileListItemProps> = ({
  file,
  onRemove,
}) => (
  <div className="flex flex-row px-3 py-2 items-center">
    <div className="mr-4">
      {file.type === 'application/pdf' ? (
        <PdfIcon className="scale-150" />
      ) : (
        <DocumentIcon />
      )}
    </div>
    <ListItemText
      primary={file.name}
      primaryTypographyProps={{ className: 'text-sm' }}
      secondary={
        file.size < 1_000_000
          ? `${(file.size / 1_000).toFixed(0)} KB`
          : `${(file.size / 1_000_000).toFixed(0)} MB`
      }
      secondaryTypographyProps={{ className: 'text-xs text-text-hint' }}
    />

    {onRemove && (
      <IconButton onClick={onRemove}>
        <DeleteIcon className="scale-150" />
      </IconButton>
    )}
  </div>
);
