import { useRef, useState, ReactNode } from 'react';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { AppBar, Button, Container } from '@material-ui/core';
import { HorizontalStepper, VerticalStepper, Show } from '@nirvana/ui-kit';
import {
  IncidentDetails,
  ReporterInformation,
  ReviewAndSubmit,
  Overview,
} from './new-claim-steps';

import { Step, STEPS } from './new-claim-steps/constants';

export const NewClaimForm = () => {
  const componentRef = useRef<null | HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(-1);
  const currentStep = STEPS[currentStepIndex];

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const screens: Record<Step, ReactNode> = {
    'incident-details': <IncidentDetails />,
    'reporter-information': <ReporterInformation />,
    'review-and-submit': (
      <ReviewAndSubmit
        onEdit={(step) => {
          const index = STEPS.findIndex((s) => s.key === step);
          setCurrentStepIndex(index);
          scrollToTop();
        }}
      />
    ),
  };

  return (
    <>
      <div className="flex flex-col md:flex-row mt-8" ref={componentRef}>
        <div>
          <Show when={currentStepIndex >= 0}>
            <div className="flex-1 p-4 mb-4 overflow-auto bg-primary-extraLight rounded-lg border-none shadow-none pt-4 md:pl-8 md:pr-20 md:py-10">
              <div className="lg:hidden">
                <HorizontalStepper
                  dataTestId="horizontal-stepper"
                  steps={STEPS}
                  activeStep={currentStepIndex}
                  onChange={(selectedStepIndex) => {
                    setCurrentStepIndex(selectedStepIndex);
                  }}
                />
              </div>
              <div className="hidden lg:block">
                <VerticalStepper
                  dataTestId="vertical-stepper"
                  steps={STEPS}
                  activeStep={currentStepIndex}
                  onChange={(selectedStepIndex) => {
                    setCurrentStepIndex(selectedStepIndex);
                  }}
                />
              </div>
            </div>
          </Show>
        </div>

        <div className="p-8 pt-0 md:p-10 md:pt-0 w-full">
          <Show when={currentStepIndex < 0}>
            <Overview />
          </Show>

          <Show when={currentStepIndex >= 0}>
            <div className="w-full">
              <div className="flex flex-col">{screens[currentStep?.key]}</div>
            </div>
          </Show>
        </div>
      </div>

      <AppBar
        color="inherit"
        elevation={1}
        position="fixed"
        sx={{ top: 'auto', bottom: 0, py: 1 }}
      >
        <Container className="flex justify-end">
          <Show when={currentStepIndex > 0}>
            <Button
              className="mr-4"
              data-testid="previous-button"
              type="button"
              variant="outlined"
              onClick={() => {
                setCurrentStepIndex(Math.max(currentStepIndex - 1, 0));
                scrollToTop();
              }}
              startIcon={<HiArrowLeft />}
            >
              Previous
            </Button>
          </Show>

          <Show when={currentStepIndex !== STEPS.length - 1}>
            <Button
              className="mr-16 lg:mr-8"
              data-testid="next-button"
              type="button"
              variant="contained"
              onClick={() => {
                setCurrentStepIndex(
                  Math.min(currentStepIndex + 1, STEPS.length - 1),
                );
                scrollToTop();
              }}
              endIcon={<HiArrowRight />}
            >
              {currentStepIndex < 0 ? 'Start' : 'Proceed'}
            </Button>
          </Show>

          <Show when={currentStepIndex === STEPS.length - 1}>
            <Button
              className="mr-16 lg:mr-8"
              data-testid="submit-button"
              type="button"
              variant="contained"
              onClick={() => console.log('Submit')}
            >
              Submit
            </Button>
          </Show>
        </Container>
      </AppBar>
    </>
  );
};
