import { Fragment, useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Button, Divider, List } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import { ReactComponent as AttachmentIcon } from 'src/assets/icons/attachment.svg';
import { FileListItem } from './FileListItem';

const MAX_FILE_SIZE = 20 * 1000 * 1000;

const acceptedFiles = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-excel': ['.xlsx'],
  'image/*': ['.jpg', '.jpeg', '.png'],
  'video/*': ['.mov', '.mkv'],
};

const maxFileSizeInMB = (MAX_FILE_SIZE / 1_000_000).toFixed(0);

const fileRejectionCodes: { [key: string]: string } = {
  'file-invalid-type': `Invalid file type.\nValid file types are ${Object.values(
    acceptedFiles,
  )
    .flat()
    .join(', ')}`,
  'file-too-large': `File is too large. Max file size is ${maxFileSizeInMB}MB`,
};

type FileUploadDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  attachments: File[];
  setAttachments: (f: File[]) => void;
};

export const FileUploadDialog = ({
  isOpen,
  onClose,
  attachments,
  setAttachments,
}: FileUploadDialogProps) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setAttachments([...attachments, ...acceptedFiles]);
    },
    [attachments],
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptedFiles,
    maxSize: MAX_FILE_SIZE,
    noClick: true,
    noKeyboard: true,
    preventDropOnDocument: true,
    onDrop: handleDrop,
    onDropRejected: (fileRejections) => {
      setRejectedFiles([...rejectedFiles, ...fileRejections]);
    },
  });

  const removeAttachment = (fileIndex: number) => {
    setAttachments(attachments.filter((_, index) => index !== fileIndex));
  };

  const closeDialog = () => {
    setRejectedFiles([]);
    onClose();
  };

  return (
    <Dialog
      actionDivider
      fullWidth
      onClose={closeDialog}
      open={isOpen}
      primaryAction={
        <Button color="primary" onClick={closeDialog} variant="contained">
          Done
        </Button>
      }
      title={<h2 className="font-medium text-xl">Upload Documents</h2>}
    >
      <div className="pb-6">
        <div
          {...getRootProps({
            className:
              'flex flex-col items-center relative py-12 md:py-16 px-5 bg-primary-extraLight rounded-lg border border-primary-light border-dashed',
          })}
        >
          <AttachmentIcon className="mb-4" />
          <h3 className="font-bold text-lg">Drag & drop file(s) here</h3>
          <p className="text-lg my-8">or</p>
          <Button
            className="w-fit"
            component="label"
            tabIndex={-1}
            variant="outlined"
          >
            Browse
            <input
              {...getInputProps()}
              id="file-uploader"
              className="hidden"
              type="file"
              multiple
            />
          </Button>

          <div className="absolute bottom-3 text-center text-xs text-text-hint">
            <p>
              Valid file types: {Object.values(acceptedFiles).flat().join(', ')}
            </p>
            <p>Max size per file: {maxFileSizeInMB}MB</p>
          </div>
        </div>
        {attachments.length > 0 && (
          <div className="flex flex-col justify-start">
            <List
              subheader={
                <div className="mt-4">
                  <span className="font-bold col-auto pl-1">
                    Uploaded files ({attachments.length})
                  </span>
                  <Divider className="mt-2 grow" />
                </div>
              }
            >
              {attachments.map((file: File, index: number) => (
                <Fragment key={`file-${file.name}-${file.type}`}>
                  <FileListItem
                    file={file}
                    onRemove={() => removeAttachment(index)}
                  />
                  <Divider />
                </Fragment>
              ))}
            </List>
          </div>
        )}

        {rejectedFiles.length > 0 && (
          <div className="flex flex-col justify-start">
            <List
              subheader={
                <div className="mt-4">
                  <span className="font-bold col-auto pl-1">
                    Rejected files ({rejectedFiles.length})
                  </span>
                  <Divider className="mt-2 grow" />
                </div>
              }
            >
              {rejectedFiles.map(({ file, errors }: FileRejection) => (
                <Fragment key={`file-${file}-${file.type}`}>
                  <div className="flex items-center justify-between">
                    <FileListItem file={file} />
                    <p className="text-text-hint w-full max-w-72">
                      {fileRejectionCodes[errors[0].code]}
                    </p>
                  </div>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </div>
        )}
      </div>
    </Dialog>
  );
};
