import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { startOfMonth, subMonths } from 'date-fns';
import { HiOutlineChartSquareBar } from 'react-icons/hi';

import SelectPeriod from 'src/components/select-period';
import {
  ExplainabilityRecommendation,
  useVehiclesQuery,
} from 'src/types/graphql-types';
import {
  analytics,
  Dates,
  getRiskScoreChartRecords,
  getViolationScore,
} from 'src/helpers';
import { Feature, useFeatureValue } from 'src/helpers/feature-flags';

import { useDOTSpecificFeature } from 'src/hooks/useDotSpecificFeature';
import { Show } from '@nirvana/ui-kit';
import Leaderboard from './components/leaderboard';
import RiskScore from './components/risk-score';
import ViolationScore from './components/violation-score';
import {
  VehicleData,
  VehicleRiskScoreBuckets,
} from './constants/leaderboard-columns';

export default function Vehicles() {
  const { reportId = '' } = useParams();
  const [period, setPeriod] = useState(24);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [vehicleData, setVehicleData] = useState<VehicleData[]>([]);
  const [isTRSDisabled, setIsTRSDisabled] = useState(false);
  const [hasTelematicsConnection, setHasTelematicsConnection] = useState(false);

  const { enabled, loading: isFlagLoading } = useDOTSpecificFeature(
    Feature.SHOW_RECOMMENDATION,
    {
      allowUserOverride: true,
    },
  );

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.VehiclesPageView,
    });
  }, []);

  useEffect(() => {
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.DateRangeFilter,
      properties: {
        module: 'vehicles',
        chart: 'Violation Count Distribution',
        period: `Last ${period} months`,
      },
    });
  }, [period]);

  const now = useMemo(() => {
    return new Date();
  }, []);

  const periodStartTime = useMemo(() => {
    // We fetch data for an extra month because
    // last month's data is not available sometimes
    return startOfMonth(subMonths(now, period + 1));
  }, [period, now]);

  const fromTimestamp = Dates.formatISOUTC(periodStartTime);
  const toTimestamp = Dates.formatISOUTC(now);
  const telematicsStartTime = Dates.formatISOUTC(subMonths(now, 2));
  const telematicsEndTime = Dates.formatISOUTC(now);

  const dotsForDisabledTRS = (
    useFeatureValue(Feature.DISABLE_SPECIFIC_DOT_TRS) || ''
  ).split(',');

  const { loading } = useVehiclesQuery({
    variables: {
      reportId,
      fromTimestamp,
      toTimestamp,
      telematicsStartTime,
      telematicsEndTime,
    },
    skip: !shouldFetch,
    onCompleted: (data) => {
      if (!data?.fleetSafetyReport) {
        return;
      }
      const vehicles = (data?.fleetSafetyReport.VehiclesStatsList?.vehicles ||
        []) as VehicleData[];
      const hideRiskScore = dotsForDisabledTRS.includes(
        data.fleetSafetyReport.dotNumber,
      );
      setIsTRSDisabled(hideRiskScore);

      const riskScores =
        data?.fleetSafetyReport?.telematicsRiskVinPercentiles || [];

      const latestRiskScorePerVin: Map<string, number> = new Map();
      const latestDriverPerVin: Map<string, VehicleData['driver']> = new Map();

      riskScores.forEach((ele) => {
        // The score retrieved is the percentage where high is good, but we want to display low is good to user
        latestRiskScorePerVin.set(ele.vin, 100 - ele.scores[0].score);
        if (
          ele.scores[0].telematicsAssignments?.length &&
          ele.scores[0].telematicsAssignments[0].driver
        ) {
          const totalAssignmentTime =
            ele.scores[0].telematicsAssignments.reduce(
              (acc, curr) => acc + curr.assignedDurationMs,
              0,
            );
          const primaryDriverAssignmentTime =
            ele.scores[0].telematicsAssignments[0].assignedDurationMs;
          if (
            totalAssignmentTime > 0 &&
            primaryDriverAssignmentTime / totalAssignmentTime > 0.7
          ) {
            latestDriverPerVin.set(
              ele.vin,
              ele.scores[0].telematicsAssignments[0].driver,
            );
          }
        }
      });

      const latestRecommendationPerVin: Map<
        string,
        ExplainabilityRecommendation
      > = new Map();
      data?.fleetSafetyReport?.telematicsVehicles?.forEach((ele) => {
        latestRecommendationPerVin.set(ele.vIN, ele.recommendations[0]);
      });

      const processedVehicles = vehicles.map((vehicle) => ({
        ...vehicle,
        riskScore: hideRiskScore
          ? undefined
          : latestRiskScorePerVin.get(vehicle.vIN || '') || undefined,
        recommendation:
          latestRecommendationPerVin.get(vehicle.vIN || '') || undefined,
        driver: latestDriverPerVin.get(vehicle.vIN || '') || undefined,
      }));

      setVehicleData(processedVehicles);
      setHasTelematicsConnection(
        data?.fleetSafetyReport?.hasTelematicsConnection,
      );

      setShouldFetch(false);
    },
  });

  const riskScore = getRiskScoreChartRecords(
    vehicleData,
    VehicleRiskScoreBuckets,
  );
  const violationScore = getViolationScore(vehicleData);

  return (
    <>
      <Show when={!isFlagLoading && !enabled}>
        <div className="mb-6 bg-white rounded-md shadow">
          <div className="flex items-center p-4 border-b">
            <div className="p-2 mr-4 text-xl rounded text-primary-dark bg-primary-extraLight">
              <HiOutlineChartSquareBar />
            </div>
            <span className="flex-1 text-xl font-semibold text-gray-800">
              Summary
            </span>
            <SelectPeriod value={period} onChange={setPeriod} />
          </div>
          <div className="grid grid-cols-2 gap-4 px-4 py-6">
            <RiskScore
              data={riskScore}
              isTelematicsConnected={hasTelematicsConnection}
              isTRSDisabled={isTRSDisabled}
              isLoading={loading}
            />
            <ViolationScore data={violationScore} isLoading={loading} />
          </div>
        </div>
      </Show>

      <Leaderboard
        isLoading={loading}
        vehicles={vehicleData}
        hasTelematicsConnection={hasTelematicsConnection}
      />
    </>
  );
}
