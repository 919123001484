import { NewClaimProvider } from 'src/contexts/newClaim';
import { NewClaimForm } from './components/NewClaimForm';

export const NewClaim = () => {
  return (
    <div className="flex flex-col bg-gray-50">
      <NewClaimProvider>
        <NewClaimForm />
      </NewClaimProvider>
    </div>
  );
};
