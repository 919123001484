import { Link, useNavigate, useParams } from 'react-router-dom';
import { HiStar, HiOutlineStar } from 'react-icons/hi';
import { Tooltip, IconButton } from '@material-ui/core';
import { Show, Switch } from '@nirvana/ui-kit';

import useAuth, { isFleetUser } from 'src/hooks/useAuth';
import { analytics } from 'src/helpers';
import {
  SearchHistoryDocument,
  useCreateReportMutation,
  useFleetDetailsQuery,
  useFleetStarMutation,
} from 'src/types/graphql-types';
import { ReactComponent as ArrowBackIcon } from 'src/assets/icons/arrow-back.svg';
import { assignDOTToUser } from 'src/helpers/analytics';

const getBackButtonProps = (
  path: string,
): { text: string; linkTo: string; onClick: any } => {
  switch (path) {
    case '/claims/new':
      return {
        text: 'All Claims',
        linkTo: 'claims',
        onClick: (e: Event) => {
          if (
            !confirm(
              'Are you sure you want to leave this page? Any unsaved changes will be lost.',
            )
          ) {
            e.preventDefault();
          }
        },
      };
    default:
      return { text: 'Back to search', linkTo: '/search', onClick: () => {} };
  }
};

/**
 * Header component is used inside AppShell to display the header.
 * It renders `Back` button, DoT Number and fleet name on the top of the page.
 */
export default function Header() {
  const { user } = useAuth();
  const { reportId = '' } = useParams();
  const navigate = useNavigate();
  const pathname =
    location.pathname.split(`/${reportId}`)[1] ?? location.pathname;
  const {
    text: backButtonText,
    linkTo,
    onClick,
  } = getBackButtonProps(pathname);

  const isAgentUser =
    user?.userType === 'agent' || user?.userType === 'nirvana';

  const [createReport] = useCreateReportMutation({
    onCompleted: ({ createFleetSafetyReport }) => {
      if (createFleetSafetyReport) {
        const currentPath = window.location.pathname.split(`/${reportId}/`)[1];
        navigate(`/${createFleetSafetyReport?.id}/${currentPath}`);
      }
    },
  });

  const { data, loading, refetch } = useFleetDetailsQuery({
    variables: { reportId },
    onCompleted: ({ fleetSafetyReport }) => {
      const { createdBy } = fleetSafetyReport || {};
      const currentUser = user?.id;

      if (!fleetSafetyReport?.dotNumber) {
        navigate('/');
        return;
      }

      if (createdBy !== currentUser) {
        return createReport({
          variables: { dotNumber: fleetSafetyReport?.dotNumber },
        });
      }

      if (currentUser && isFleetUser(user)) {
        assignDOTToUser(currentUser, fleetSafetyReport?.dotNumber);
      }
    },
  });

  const isStarred = data?.fleetSafetyReport?.starred;

  const [fleetStarMutation] = useFleetStarMutation({
    variables: { reportId, markStar: !isStarred },
    refetchQueries: [{ query: SearchHistoryDocument }],
    onCompleted: () => refetch(),
  });

  const handleStarClick = () => {
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.StarDOT,
      properties: {
        dotNumber: data?.fleetSafetyReport?.dotNumber,
        reportId,
      },
    });

    fleetStarMutation();
  };

  return (
    <header className="flex flex-wrap items-center justify-between px-6 py-2 shadow">
      <Show when={isAgentUser} fallback={<div />}>
        <Link
          to={linkTo}
          className="flex items-center gap-x-2 group mb-5 md:mb-0"
          onClick={onClick}
        >
          <span className="p-2 rounded-full group-hover:bg-gray-100">
            <ArrowBackIcon />
          </span>
          <span>{backButtonText}</span>
        </Link>
      </Show>

      <div className="flex items-center justify-center w-full space-x-4 md:w-auto">
        <Switch>
          <Switch.Match when={loading}>
            <div className="w-32 h-6 bg-gray-100 rounded animate-pulse" />
          </Switch.Match>
          <Switch.Match when={data}>
            <span className="text-sm font-medium text-secondary-main">
              {data?.fleetSafetyReport?.name}
            </span>
          </Switch.Match>
        </Switch>

        <div className="w-px h-4 bg-primary-light/25" />

        <Switch>
          <Switch.Match when={loading}>
            <div className="w-24 h-6 bg-gray-100 rounded animate-pulse" />
          </Switch.Match>
          <Switch.Match when={data}>
            <span className="inline-block text-sm font-medium text-secondary-main">
              {data?.fleetSafetyReport?.dotNumber}
            </span>
          </Switch.Match>
        </Switch>

        <Show when={!isFleetUser(user)} fallback={<div />}>
          <Tooltip title={`Add${isStarred ? 'ed' : ''} to Starred`} arrow>
            <IconButton size="small" onClick={handleStarClick}>
              <Show when={isStarred} fallback={<HiOutlineStar />}>
                <HiStar className="w-5 h-5 text-gold-main" />
              </Show>
            </IconButton>
          </Tooltip>
        </Show>
      </div>

      <IconButton size="small" />
    </header>
  );
}
