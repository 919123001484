import { useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { constants } from '@nirvana/ui-kit';
import { NewClaimContext, DESCRIPTION_MAX_LENGTH } from 'src/contexts/newClaim';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { ReactComponent as FileUploadIcon } from 'src/assets/icons/upload-icon.svg';
import { usePoliciesQuery, PolicyState } from 'src/types/graphql-types';
import { policyCoveragesMapping } from './constants';
import { FileUploadDialog } from './FileUploadDialog';
import { DateTimePicker } from './DateTimePicker';

export const IncidentDetails = () => {
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  const { register, getValues, setValue, watch } = useContext(NewClaimContext);
  const form = getValues();

  const lossDate = watch('lossDate');
  const { data: policiesData, loading: isLoadingPolicies } = usePoliciesQuery({
    skip: !lossDate || !form.insuredDOT,
    variables: {
      activeDateIn: lossDate.toISOString(),
      dotNumber: form.insuredDOT,
      policyState: PolicyState.Active,
    },
  });

  const noPolicies = policiesData?.policies.length === 0;
  return (
    <div className="flex flex-col">
      <h3 className="text-base font-bold text-3xl">Incident Details</h3>

      <div className="flex flex-col w-full mt-8 bg-white rounded-lg shadow p-8">
        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">
            Incident Time and Location
          </p>
          <p className="mt-4 text-text-hint font-normal text-sm">
            The date and time of the incident, and where it occurred.
          </p>
        </div>

        <div className="mt-4 w-full">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex-1">
              <InputLabel
                required
                className="mb-2 text-primary-main text-normal font-normal text-sm"
              >
                Time of Incident
              </InputLabel>

              <DateTimePicker
                value={lossDate}
                onChange={(v) => setValue('lossDate', v)}
              />
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel
                required
                className="mb-2 text-primary-main text-normal font-normal text-sm"
              >
                State
              </InputLabel>

              <Select
                {...register('lossState')}
                fullWidth
                value={watch('lossState')}
                variant="outlined"
              >
                {constants.usStates.map(
                  (state: { name: string; code: string }) => (
                    <MenuItem key={state.code} value={state.code}>
                      {state.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                Location Details
              </InputLabel>
              <OutlinedInput
                {...register('lossLocation')}
                placeholder="Enter closest city, landmark, etc."
                fullWidth
              />
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Policy Information</p>
          <p className="mt-4 text-text-hint font-normal text-sm">
            Verify the policy number to which this incident is related to. If
            you&apos;re unsure, just select Auto Liability.
          </p>
        </div>

        <div className="mt-4 w-2/4">
          <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
            Policy Number
          </InputLabel>

          <div className="flex">
            <Select
              {...register('policyNumber')}
              fullWidth
              value={watch('policyNumber')}
              variant="outlined"
              disabled={isLoadingPolicies || noPolicies}
              displayEmpty={noPolicies}
            >
              {noPolicies ? (
                <div>
                  No active policies at incident time (you can still proceed)
                </div>
              ) : (
                policiesData?.policies.map((policy) => (
                  <MenuItem
                    key={policy.id}
                    value={policy.policyNumber}
                    onClick={() =>
                      setValue('lineOfBusiness', policy.coverages[0])
                    }
                  >
                    {`${policy.policyNumber} / ${policyCoveragesMapping[policy.coverages[0]]}`}
                  </MenuItem>
                ))
              )}
            </Select>

            <div className="ml-4 max-w-10 max-h-10 w-full">
              {isLoadingPolicies && <CircularProgress className="p-2" />}
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <p className="text-base font-medium text-md">Vehicle Information</p>
        <div className="mt-4">
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="w-full max-w-52">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Was your vehicle involved?
                </InputLabel>
                <RadioGroup row defaultValue={form.ownVehiclesInvolved}>
                  <FormControlLabel
                    {...register('ownVehiclesInvolved')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('ownVehiclesInvolved')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="w-full ml-0 mt-4 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Insured Vehicles (VIN)
                </InputLabel>

                {watch('insuredVehicleVins')?.map(
                  (vin: string, index: number) => (
                    <div
                      className="flex mb-2 mt-4 first:mt-0"
                      key={`insuredVin-${index}`}
                    >
                      <OutlinedInput
                        {...register(`insuredVehicleVins.${index}`)}
                        placeholder="5N1AR1NN6CC625862"
                        disabled={watch('ownVehiclesInvolved') === 'no'}
                        className="w-full max-w-52"
                        fullWidth
                        value={vin}
                      />
                      {index === 0 ? (
                        <IconButton
                          className="text-sm"
                          disabled={watch('ownVehiclesInvolved') === 'no'}
                          onClick={() => {
                            setValue('insuredVehicleVins', [
                              ...form.insuredVehicleVins,
                              '',
                            ]);
                          }}
                        >
                          <div className="flex flex-row items-center justify-center">
                            {' '}
                            <Add fontSize="small" />{' '}
                            <p className="ml-1">Add VIN</p>
                          </div>
                        </IconButton>
                      ) : (
                        <IconButton
                          className="text-sm"
                          onClick={() => {
                            setValue(
                              'insuredVehicleVins',
                              form.insuredVehicleVins.filter(
                                (_: string, i: number) => i !== index,
                              ),
                            );
                          }}
                        >
                          <DeleteIcon className="scale-100" />
                        </IconButton>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>

            <div className="flex flex-col mt-4 md:flex-row">
              <div className="w-full max-w-52">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Were other vehicles involved?
                </InputLabel>
                <RadioGroup row defaultValue={form.otherVehiclesInvolved}>
                  <FormControlLabel
                    {...register('otherVehiclesInvolved')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('otherVehiclesInvolved')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="w-full ml-0 mt-4 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Other Vehicles (License Plate)
                </InputLabel>

                {watch('otherVehicleVins')?.map(
                  (vin: string, index: number) => (
                    <div
                      className={`flex mb-2 ${index === 0 ? 'mt-4' : ''}`}
                      key={`insuredVin-${index}`}
                    >
                      <OutlinedInput
                        {...register(`otherVehicleVins.${index}`)}
                        disabled={watch('otherVehiclesInvolved') === 'no'}
                        placeholder="JHL4783"
                        className="max-w-52"
                        fullWidth
                        value={vin}
                      />
                      {index === 0 ? (
                        <IconButton
                          className="text-sm"
                          disabled={watch('otherVehiclesInvolved') === 'no'}
                          onClick={() => {
                            setValue('otherVehicleVins', [
                              ...form.otherVehicleVins,
                              '',
                            ]);
                          }}
                        >
                          <div className="flex flex-row items-center justify-center">
                            {' '}
                            <Add fontSize="small" />{' '}
                            <p className="ml-1">Add Vehicle</p>
                          </div>
                        </IconButton>
                      ) : (
                        <IconButton
                          className="text-sm"
                          onClick={() => {
                            setValue(
                              'otherVehicleVins',
                              form.otherVehicleVins.filter(
                                (_: string, i: number) => i !== index,
                              ),
                            );
                          }}
                        >
                          <DeleteIcon className="scale-100" />
                        </IconButton>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <p className="text-base font-medium text-md">Law Enforcement</p>
        <div className="mt-4">
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="w-full flex-1">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Was law enforcement present at the scene of the incident?
                </InputLabel>
                <RadioGroup row defaultValue={form.police.onTheScene}>
                  <FormControlLabel
                    {...register('police.onTheScene')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('police.onTheScene')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="ml-0 mt-4 md:mt-0 md:ml-12 flex-1">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Was anyone injured?
                </InputLabel>

                <RadioGroup row defaultValue={form.injureds}>
                  <FormControlLabel
                    {...register('injureds')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('injureds')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="flex-1">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Law Enforcement Agency Name
                </InputLabel>
                <OutlinedInput
                  {...register('police.agencyName')}
                  disabled={watch('police.onTheScene') === 'no'}
                  fullWidth
                />
              </div>

              <div className="ml-0 mt-4 md:mt-0 md:ml-12 flex-1">
                <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
                  Report Number
                </InputLabel>
                <OutlinedInput
                  {...register('police.reportNumber')}
                  disabled={watch('police.onTheScene') === 'no'}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Incident Description</p>
          <p className="mt-4 text-text-hint font-normal text-sm">
            Share as much detail as possible to speed up the claim process.
          </p>
        </div>

        <div className="mt-4 w-full">
          <InputLabel className="mb-2 text-primary-main text-normal font-normal text-sm">
            What happened?
          </InputLabel>

          <OutlinedInput
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
            {...register('description')}
          />
          <p className="text-end text-text-hint text-xs mt-1 mr-2">
            {watch('description')?.length || 0}/{DESCRIPTION_MAX_LENGTH}
          </p>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">
            Supporting Reports, Images and Videos
          </p>
          <p className="mt-4 text-text-hint font-normal text-sm">
            Upload any documentation that you have about this incident so far:
            images, police reports, receipts, other documents.
          </p>
        </div>

        <div className="mt-4">
          <Button
            onClick={() => setIsFileUploadDialogOpen(true)}
            startIcon={<FileUploadIcon />}
            variant="outlined"
          >
            Upload
          </Button>

          <FileUploadDialog
            isOpen={isFileUploadDialogOpen}
            onClose={() => setIsFileUploadDialogOpen(false)}
            attachments={watch('attachments')}
            setAttachments={(attachments: File[]) =>
              setValue('attachments', attachments)
            }
          />
        </div>
      </div>
    </div>
  );
};
