import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Rating,
} from '@material-ui/core';
import {
  UserProfileResponse,
  UserProfileResponseUserTypeEnum,
} from '@nirvana/api/auth';
import { Dialog, InputWithLabel, Show } from '@nirvana/ui-kit';
import { SnackbarOrigin, VariantType } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useSnackbar from 'src/hooks/useSnackbar';
import {
  ClaimsByDotNumberQuery,
  FeedbackCategory,
  useCreateClaimFeedbackMutation,
} from 'src/types/graphql-types';
import { z } from 'zod';

const MAX_RATING = 5;

const claimFeedbackSchema = z
  .object({
    rating: z.number().min(1).max(5),
    claimId: z.string(),
    category: z.nativeEnum(FeedbackCategory, {
      required_error: 'Please select a category',
    }),
    feedback: z.string(),
  })
  .refine(
    (data) => {
      if (data.rating === MAX_RATING) {
        return true;
      }
      if (!data.category || data.category.length === 0) {
        return false;
      }
      return true;
    },
    {
      message: 'Please select a category',
      path: ['category'],
    },
  );

type ClaimFeedbackType = z.infer<typeof claimFeedbackSchema>;

export const ClaimRating = ({
  userType,
  feedback,
}: {
  feedback: ClaimsByDotNumberQuery['claimsByDOTNumber'][0]['feedbacks'][0];
  userType?: UserProfileResponse['userType'];
}) => {
  const { claimId, rating: currentFeedback } = feedback ?? {};

  const [showDialog, setShowDialog] = useState(false);
  const [numericRating, setNumericRating] = useState<number>(0);
  const [shouldResetNumericRating, setShouldResetNumericRating] =
    useState<boolean>(true);

  const enqueueSnackbar = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ClaimFeedbackType>({
    mode: 'onChange',
    resolver: zodResolver(claimFeedbackSchema),
    defaultValues: {
      claimId,
    },
  });

  const handleRatingChange = (
    _: React.SyntheticEvent,
    rating: number | null,
  ) => {
    if (rating === null) {
      return;
    }
    setValue('rating', rating);
    setNumericRating(rating);
    if (rating === MAX_RATING) {
      setValue('category', FeedbackCategory.OutstandingService);
    }
    setShouldResetNumericRating(true);
    setShowDialog(true);
  };

  const closeModal = () => {
    setShowDialog(false);
  };

  const closeAndReset = ({ resetNumericRating = false } = {}) => {
    closeModal();
    reset();
    setShouldResetNumericRating(resetNumericRating);
  };

  const snackBarOptions = (variant: VariantType) => {
    return {
      anchorOrigin: {
        vertical: 'bottom' as SnackbarOrigin['vertical'],
        horizontal: 'center' as SnackbarOrigin['horizontal'],
      },
      variant,
    };
  };

  const [submitFeedback, { loading: savingFeedback, error: submitError }] =
    useCreateClaimFeedbackMutation();

  const handleFeedbackModalSubmit = (data: ClaimFeedbackType) => {
    submitFeedback({
      variables: {
        claimId,
        rating: data.rating,
        category: data.category,
        value: data.feedback,
      },
      onCompleted: () => {
        closeAndReset();
        enqueueSnackbar(
          'Thank you for your feedback!',

          'We appreciate your feedback and will use it to improve our service.',
          snackBarOptions('success'),
        );
      },

      onError: ({ graphQLErrors }) => {
        let message =
          graphQLErrors?.toString() || 'Something went wrong, please try again';
        if (message.includes('already closed and has a feedback')) {
          message =
            'You have already submitted feedback rating for this closed claim.';
        }
        enqueueSnackbar('', message, snackBarOptions('error'));
      },
    });
  };
  const showLoading = !submitError && savingFeedback;

  return (
    <>
      <Dialog
        open={showDialog}
        TransitionProps={{
          onExited: () => {
            if (shouldResetNumericRating) {
              setNumericRating(0);
            }
          },
        }}
        onClose={() => {
          closeAndReset({
            resetNumericRating: true,
          });
        }}
        primaryAction={
          <Button
            type="submit"
            variant="contained"
            onClick={
              showLoading ? () => {} : handleSubmit(handleFeedbackModalSubmit)
            }
          >
            {showLoading ? 'Saving...' : 'Submit'}
          </Button>
        }
        secondaryAction={
          <Button
            variant="text"
            onClick={() => {
              closeAndReset({
                resetNumericRating: true,
              });
            }}
          >
            Cancel
          </Button>
        }
      >
        <div className="md:w-96">
          <h2 className="text-xl font-bold" data-testid="feedback-dialog-title">
            {numericRating === MAX_RATING
              ? 'Thanks for your feedback!'
              : 'How can we improve?'}
          </h2>
          <div className="flex justify-center my-4">
            <Rating size="large" value={numericRating} readOnly />
          </div>
          <form onSubmit={handleSubmit(handleFeedbackModalSubmit)}>
            <Show when={numericRating !== null && numericRating < MAX_RATING}>
              <FormControl error={!!errors.category?.message}>
                <label
                  id="category-label"
                  data-testid="category-label"
                  className=" text-text-hint"
                >
                  Please choose the option that most closely relates to your
                  experience:
                </label>
                <RadioGroup
                  aria-labelledby="category-label"
                  name="category-radio-buttons-group"
                  className="mt-4"
                  onChange={(e) => {
                    setValue('category', e.target.value as FeedbackCategory);
                  }}
                >
                  <FormControlLabel
                    value={FeedbackCategory.FrequentAdjusterCommunication}
                    control={<Radio />}
                    label="Frequent adjuster communication"
                  />
                  <FormControlLabel
                    value={FeedbackCategory.FasterClaimResolution}
                    control={<Radio />}
                    label="Faster claim resolution"
                  />
                  <FormControlLabel
                    value={FeedbackCategory.OfferFairerCompensation}
                    control={<Radio />}
                    label="Offer fairer compensation"
                  />
                  <FormControlLabel
                    value={FeedbackCategory.RequireFewerDocuments}
                    control={<Radio />}
                    label="Require fewer documents"
                  />
                  <FormControlLabel
                    value={FeedbackCategory.ProvideBetterSupport}
                    control={<Radio />}
                    label="Provide better support"
                  />
                  <FormControlLabel
                    value={FeedbackCategory.Other}
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
                <FormHelperText>{errors.category?.message}</FormHelperText>
              </FormControl>
            </Show>

            <InputWithLabel
              label=""
              placeholder="Please provide a brief explanation of your rating to help us improve your experience."
              {...register('feedback')}
              multiline
              rows={5}
              formControlProps={{
                className: 'w-full -mt-2',
              }}
              error={!!errors.feedback}
              helperText={errors.feedback?.message}
            />
          </form>
        </div>
      </Dialog>
      <Rating
        value={numericRating > 0 ? numericRating : currentFeedback}
        key={numericRating}
        readOnly={userType !== UserProfileResponseUserTypeEnum.Fleet}
        onChange={handleRatingChange}
      />
    </>
  );
};
