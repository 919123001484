import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { analytics, Dates } from 'src/helpers';
import { useViolationsQuery } from 'src/types/graphql-types';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  RowSelectionState,
} from '@tanstack/react-table';
import { Table } from 'src/components/table/Table';
import { FilterContainer, getFormattedDateInTimezone } from '@nirvana/ui-kit';
import { BASICS, ExtendedCategory } from 'src/constants';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import { TablePagination } from '@material-ui/core';
import { useTableQueryParams } from 'src/hooks/useTableQueryParams';
import parseISO from 'date-fns/parseISO';
import differenceInMonths from 'date-fns/differenceInMonths';
import {
  JointInspectionsData,
  getColumns,
  filterFns,
} from './constants/columns';
import FilterBar from './components/filter-bar';
import SearchBar from './components/search-bar';
import { ExpiringViolations } from './components/ExpiringViolations';

const getWeightForViolation = (violation: {
  severityWeight: number;
  inspectionDate: string;
}) => {
  const weight = violation.severityWeight;
  const timeAgo = differenceInMonths(
    new Date(),
    parseISO(violation.inspectionDate),
  );
  let weightFactor = 3;
  if (timeAgo >= 6 && timeAgo < 12) {
    weightFactor = 2;
  } else if (timeAgo >= 12) {
    weightFactor = 1;
  }

  return weight * weightFactor;
};

export default function Violations() {
  const { reportId = '' } = useParams();
  const {
    setFilters,
    columnFilters,
    globalFilter,
    setGlobalFilter,
    period,
    setPeriod,
  } = useTableQueryParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const fromTimestamp = useMemo(() => {
    const startMonth = startOfMonth(subMonths(new Date(), period + 1));
    return Dates.formatISOUTC(startMonth);
  }, [period]);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  const datagovStartDate = useMemo(() => {
    const startMonth = startOfMonth(subMonths(new Date(), 2));
    return Dates.formatISOUTC(startMonth);
  }, []);

  const datagovEndDate = useMemo(() => {
    return Dates.formatISOUTC(new Date());
  }, []);

  const [filteredInspections, setFilteredInspections] = useState<
    JointInspectionsData[]
  >([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const [shouldShowConnectFMCSA, setShouldShowConnectFMCSA] =
    useState<boolean>(false);
  const [shownColumns, setShownColumns] = useState<{
    [key: string]: boolean;
  }>({});

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { loading } = useViolationsQuery({
    variables: {
      reportId,
      fromTimestamp,
      toTimestamp,
      datagovStartDate,
      datagovEndDate,
    },
    skip: !shouldFetch,
    onCompleted: (data) => {
      const modifiedData =
        data?.fleetSafetyReport?.inspections
          .filter((inspection) => inspection.violations.length > 0)
          .map((inspection) =>
            inspection.violations.map((violation) => ({
              ...violation,
              inspectionID: inspection.inspectionID,
              inspectionDate: inspection.inspectionDate,
              // Prefer public data VINs if they are available, or else fall back to private data vehicles list.
              vINs:
                inspection.publicVINs && inspection.publicVINs.length
                  ? inspection.publicVINs
                  : (inspection.vehicles?.length
                      ? inspection.vehicles
                      : []
                    ).map(({ vin }) => vin),
              location: {
                countyCode: inspection.countyCode,
                countyName: inspection.countyName,
              },
              driver: {
                name: inspection.driver?.name.replace(',', ''),
                licenseNumber: inspection.driver?.licenseNumber,
              },
              ponderatedWeight: getWeightForViolation({
                severityWeight: violation.severityWeight,
                inspectionDate: inspection.inspectionDate,
              }),
              formattedInspectionDate: getFormattedDateInTimezone(
                inspection.inspectionDate,
              ),
              dateInSeconds: parseISO(inspection.inspectionDate).getTime(),
            })),
          )
          .flat() ?? [];

      // Identify the latest inspectionDate across all violations in modifiedData.
      const latestInspectionDate = modifiedData.reduce(
        (acc: string, violation) => {
          return acc > violation.inspectionDate
            ? acc
            : violation.inspectionDate;
        },
        '',
      );

      const modifiedDatagovData =
        data?.fleetSafetyReport?.datagovInspections
          .filter((inspection) => inspection.violations.length > 0)
          .map((inspection) => {
            const seenViolationCodes = new Set();
            return inspection.violations.map((violation) => {
              const code = `${violation.partNumber}.${violation.partNumberSection}`;
              const isPending = violation.code !== code;
              const isDuplicate = !isPending && seenViolationCodes.has(code);
              const severityValue =
                isPending || isDuplicate ? 0 : violation.severity ?? 0;
              const severityLabel = isPending
                ? ExtendedCategory.Pending
                : severityValue;
              const severityOOSValue =
                severityValue > 0 && violation.oos
                  ? severityValue + 2
                  : severityValue;
              const description = `${
                violation.description ??
                'Awaiting detailed violation information from FMCSA'
              }${
                isDuplicate
                  ? ' (Duplicate violation is not used in SMS score calculations)'
                  : ''
              }`;

              seenViolationCodes.add(code);
              return {
                rowID: 'datagov-violation-' + violation.id,
                inspectionID: inspection.id,
                group: violation.group ?? {
                  name: 'Unspecified',
                  category: ExtendedCategory.Pending,
                  severity: 0,
                  humanReadable: BASICS[ExtendedCategory.Pending],
                },
                violationID: violation.id,
                code,
                category:
                  violation?.group?.category || ExtendedCategory.Pending,
                oosIndicator: violation.oos ?? false,
                timeWeight: 3,
                severityWeight: severityLabel,
                totalSeverityWeight: severityOOSValue,
                humanReadableCode: `${code}${isPending ? ': Awaiting' : ''}`,
                description,
                isDSMS: !!violation.group,
                oosWeight: !isDuplicate && violation.oos ? 2 : 0,
                publishedDate: '',
                inspectionDate: inspection.inspectionDate,
                vINs: violation.vehicle?.vin
                  ? [violation.vehicle?.vin]
                  : inspection.vehicles && inspection.vehicles.length
                  ? inspection.vehicles
                      .map(({ vin }) => vin || '')
                      .filter((vin) => vin !== '')
                  : [],
                location: {
                  countyCode: inspection.countyCode,
                  countyName: inspection.countyName,
                },
                driver: {
                  name: undefined,
                  licenseNumber: undefined,
                },
                ponderatedWeight: getWeightForViolation({
                  severityWeight:
                    !isDuplicate && violation.severity ? violation.severity : 0,
                  inspectionDate: inspection.inspectionDate,
                }),
                formattedInspectionDate: getFormattedDateInTimezone(
                  inspection.inspectionDate,
                ),
                dateInSeconds: parseISO(inspection.inspectionDate).getTime(),
              };
            });
          })
          .flat() ?? [];

      // Run before adding the pending inspections
      setShouldShowConnectFMCSA(
        modifiedData.every((inspection) => inspection.driver?.name),
      );

      const newerThanPublicInspectionDate = modifiedDatagovData.filter(
        (inspection) => inspection.inspectionDate > latestInspectionDate,
      );

      setRowSelection(
        newerThanPublicInspectionDate.reduce((acc, inspection) => {
          acc[inspection.rowID] = true;
          return acc;
        }, {} as RowSelectionState),
      );

      const mergedData = [
        ...modifiedData,
        ...modifiedDatagovData.filter(
          (violation) => violation.inspectionDate > latestInspectionDate,
        ),
      ];
      setFilteredInspections(mergedData);
      setShouldFetch(false);

      setShownColumns({
        driver: modifiedData.some((inspection) => inspection.driver?.name),
      });
    },
  });

  const columns = useMemo(() => getColumns(), []);

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.ViolationsPageView,
    });
  }, []);

  const table = useReactTable({
    columns,
    data: filteredInspections,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onGlobalFilterChange: setFilters,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.rowID,
    globalFilterFn: 'globalFilter' as any,
    autoResetPageIndex: false, // this should be true, but it looks like something is triggering the reset
    initialState: {
      sorting: [
        {
          id: 'inspectionDate',
          desc: true,
        },
        {
          id: 'inspectionID',
          desc: true,
        },
        {
          id: 'humanReadableCode',
          desc: false,
        },
      ],
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
      columnVisibility: shownColumns,
      rowSelection,
    },
    filterFns,
  });

  const keyValueMappings = useMemo(() => {
    return filteredInspections?.reduce(
      (acc, element) => {
        return {
          group_name: {
            ...acc.group_name,
            [element.group.name]: element.group.humanReadable,
          },
          category: {
            ...acc.category,
            [element.category]: BASICS[element.category],
          },
          location_countyCode: {
            ...acc.location_countyCode,
            [element.location.countyCode]: element.location.countyName,
          },
        };
      },
      {
        group_name: {},
        category: {},
        location_countyCode: {},
      },
    );
  }, [filteredInspections]);

  return (
    <div>
      <ExpiringViolations
        loading={loading}
        violations={table.getFilteredRowModel().rows.map((row) => row.original)}
        dateColumn={table.getColumn('inspectionDate')}
      />
      <SearchBar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        period={period}
        shouldShowConnectFMCSA={shouldShowConnectFMCSA}
        setPeriod={(period) => {
          setPeriod(period);
          setShouldFetch(true);
        }}
      />
      <FilterContainer>
        <FilterBar
          table={table}
          filters={columnFilters}
          setFilter={setFilters}
          keyValueMappings={keyValueMappings}
        />
      </FilterContainer>
      <div className="flex-1 p-4 mb-4 overflow-auto bg-white rounded-md shadow">
        <Table table={table} loading={loading} />

        <div className="flex justify-end">
          <TablePagination
            data-testid="pagination"
            colSpan={3}
            rowsPerPageOptions={[10, 25, 50]}
            count={table.getFilteredRowModel().rows.length}
            page={table.getState().pagination.pageIndex}
            rowsPerPage={table.getState().pagination.pageSize}
            onPageChange={(_, page) => {
              table.setPageIndex(page);
            }}
            onRowsPerPageChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          />
        </div>
      </div>
      <div className="h-4" />
    </div>
  );
}
